body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    /* font-family: 'Roboto Mono', monospace; */
    font-family: 'Poppins', sans-serif;
/* font-family: 'Roboto Mono', monospace; */
    /* font-family: 'Raleway', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url('./assets/one.jpg'); */

  background-position: center;
  background-repeat: no-repeat;
  background-size:cover;
  min-height: 100vh;
  background-color: rgba(245, 245, 245, 0.9);
  /* #252a29 #727877 #8d9693 */
  /* background-image: radial-gradient(  #8d9693 70%,#727877,#252a29  ); */
}

code {
  
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Roboto Mono', monospace; */
  /* font-family: 'Raleway', sans-serif; */
}

Link{
  text-decoration: none !important;
}


.ArticleCopy{ 
  width: 80%;
  margin:0 auto;
  /* border: 1px solid #ddd; */
  padding-top: 3rem;
}


.ArticleCopy blockquote {
    border-radius: 10px;
    position: relative;  /*  <--- */
    /* font-style: italic; */
    text-align: center;
    padding: 1rem 1.2rem;
    width: 80%;  /* create space for the quotes */
    color: #4a4a4a;
    margin: 1rem auto 2rem;
    color: #4a4a4a;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ArticleCopy h4 {
    border-radius: 10px;
    position: relative;  /*  <--- */    
    text-align: center;
    padding: 1rem 1.2rem;
    width: 80%;  /* create space for the quotes */
    color: #4a4a4a;
    margin: 1rem auto 2rem;
    color: #4a4a4a;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
/* -- create the quotation marks -- */
.ArticleCopy blockquote:before,
.ArticleCopy blockquote:after{
    font-family: FontAwesome;
    position: absolute;
    /* -- inside the relative position of blockquote -- */
    top: 13px;
    color: #fff;
    font-size: 34px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
}
.ArticleCopy blockquote:before{
    content: "\f10d";
    margin-right: 19px;
    right: 100%;
}
.ArticleCopy blockquote:after{
    content: "\f10e";
    margin-left: 13px;
    left: 100%;  
    top: auto;
    bottom: 13px;
}

.language{
  padding: 20px; 
  text-align: center;
}

.langLink{
 color: #000;
 text-decoration: none !important;
}

.MuiButton-outlinedSizeSmall{
  padding-left: 2px !important;
  padding-right: 2px !important;
  margin-top: 4px !important; 
  margin-bottom: 4px !important; 
  margin-left: 10px !important; 
  margin-right: 10px !important; 
}
@media only screen and (min-width: 600px){
  .mobile-logo{
    display: none !important;
  }
  .reverse{
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (max-width: 600px)
{
  .st-label{
  display: none !important;
}
h1{
  font-size: 20px !important;
}
h3{
  font-size: 12px !important;
}
.st-btn{
  min-width: 50px !important;
}
.mobile-menu{
  direction:"row-revers" !important ;
}
.desktop-logo{
  display: none !important;
}
.img{
  width: 70px !important;
}
.reverse{
  display: flex;
  flex-direction:row-reverse;
  justify-content: space-between;
  align-items: center;
}
}